import * as React from "react";
import Layout from "../components/layout";

// markup
const PrivacyPage = () => {
    return (
        <main>
            <title>Privacy Policy</title>
            <Layout>
                <div className="mx-auto items-center flex justify-center">
                    <p className="text-xl">
                        Holler at us:{" "}
                        <a href="mailto:support@momolingo.com">
                            support@momolingo.com
                        </a>
                        .
                    </p>
                </div>
            </Layout>
        </main>
    );
};

export default PrivacyPage;
